<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Menu</h2>
                <BasicFormToolbar :actions="['new','refresh']"  @new="openNew" @refresh="refresh"/>
                <BasicDatatable @edited="edit" @deleted="confirmDelete" :headers="headers" :rows="entities" :rowaction="true" :rowdelete="true" :rowedit="true"/>
                <Dialog v-model:visible="newDialog" :style="{ width: '70%' }" :header="!editMode ? 'Nuevo Menu' : 'Editar Menu'" :modal="true">
                    <TabView ref="tab">
                        <TabPanel header="Datos Generales">
                            <div class="p-fluid formgrid grid">
                                <FormInputText wrapperClass="field col-6" label="ID" v-model="entity.id" :valid="validate.validations.id" />
                                <FormInputText wrapperClass="field col-6" label="Padre" v-model="entity.parent" />
                                <FormInputText wrapperClass="field col-12" label="Nombre" v-model="entity.name" :valid="validate.validations.name" />
                                <FormInputText wrapperClass="field col-12" label="URL" v-model="entity.url" />
                                <div class="field col-2">
                                    <label for="name">Icono</label>
                                    <Dropdown
                                        v-model="entity.icon"
                                        :options="[
                                            {
                                                name: 'Archivo',
                                                id: 'pi pi-fw pi-file',
                                                value: 'pi pi-fw pi-file',
                                            },
                                            {
                                                name: 'Folder',
                                                id: 'pi pi-fw pi-folder',
                                                value: 'pi pi-fw pi-folder',
                                            },
                                        ]"
                                        optionValue="value"
                                        :class="{ 'p-invalid': validate.validations.icon === false }"
                                    >
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value">
                                                <span :class="slotProps.value.toLowerCase()" />
                                                <div>{{ slotProps.value.name }}</div>
                                            </div>
                                            <span v-else> Seleccione un icono </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div><span :class="slotProps.option.id.toLowerCase()" />{{ slotProps.option.name }}</div>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid" v-if="validate.validations.icon === false"> Favor de seleccionar un icono </small>
                                </div>
                                <div class="field col-10">
                                    <label for="Acciones">Acciones</label>
                                    <MultiSelect v-model="entity.actions" :filter="true" :options="actions" optionValue="value" optionLabel="name" display="chip" />
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="newDialog = false" />
                        <Button label="Guardar" icon="pi pi-check" class="p-button-text" :loading="loading" @click="save" />
                    </template>
                </Dialog>
                <DeleteDialogComponent v-model="deleteDialog" @deleted="deleteEntity" @closed="deleteDialog = false" />
            </div>
        </div>
    </div>
</template>

<script>
import { Menu } from '../../models/seguridad/Menu';
import { FilterMatchMode } from 'primevue/api';
import { HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast } from '../../utilities/General';
import Loader from '../../components/general/Loader.vue';
import Session from '../../mixins/sessionMixin';
import BasicDatatable from '../../components/general/BasicDatatable.vue'
import FormInputText from '../../components/general/FormInputText.vue'
import BasicFormToolbar from '../../components/general/BasicFormToolbar.vue'
import DeleteDialogComponent from '../../components/general/DeleteDialog.vue'
import { actions } from "../../utilities/MENU_ACTIONS";

export default {
    mixins: [Session],
    data() {
        return {
            entity: null,
            editMode: false,
            entities: [],
            actions: actions,
            //* URL para subir el importador
            uploadURL: null,
            newDialog: false,
            deleteDialog: false,
            filters: {},
            //* Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera
            //* 'required' de tipo 'text'
            rules: [new Rule({ name: 'id' }), new Rule({ name: 'name' }), new Rule({ name: 'icon' })],
            //* Aqui agregamos el estatus de la validacion, inicializar en las props 'null'
            //* y el valid general en false (por que no esta validado aun)
            validate: {
                valid: false,
                validations: {
                    id: null,
                    name: null,
                    icon: null,
                },
            },
            headers: [new HeaderGrid('ID', 'id'), new HeaderGrid('Padre', 'parent'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('URL', 'url'), new HeaderGrid('Icon', 'icon', { type: 'icon' })],
            loading: false,
        };
    },
    components: { DeleteDialogComponent, Loader, BasicDatatable, FormInputText, BasicFormToolbar },
    methods: {
        openNew() {
            this.entity = new Menu(this.session);
            this.newDialog = true;
            this.editMode = false;
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                if (this.entity.parent == '') {
                    this.entity.parent = null;
                }
                if (this.entity.url == '') {
                    this.entity.url = null;
                }
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.editMode) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    //* Creamos uno nuevo
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra

                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.entity = new Menu(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, {...entity});
            this.newDialog = true;
            this.editMode = true;
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, {...entity});
            this.deleteDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Menu(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        uploadError() {
            this.$toast.add(new ErrorToast('Error al intentar importar archivo'));
        },
        async refresh() {
            this.loading = true;
            try {
                this.entities = await this.entity.all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
    created() {
        console.log(this.session);
        this.entity = new Menu(this.session);
        this.initFilters();
    },
    async mounted() {
        await this.refresh();
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
